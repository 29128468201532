export const environment = {
  production: true,
  env: 'prod',
  use_hash_for_routing: false,
  recaptchaKey: '6Lff6ZgUAAAAAKtOowlzb97c72D1Rd9GkEUw2ylL',

  site_id: 'prepssm',
  site_name: 'prepSSM',
  defaultLanguage: 'it',
  locale: 'it-IT',
  api_base_url: 'https://api-v2.prepssm.it/api',
  stripe_api_key: 'pk_live_7VtaqtnTio9UKiy28u9D3vV600bRSVv4Ag',
  google_web_id: '838881160783-jcnehqrsg49421cope3n4mbhbuj058b5.apps.googleusercontent.com',
  pusher_id: 'fc4e85dd9838c22bfb3c',
  fb_id: '2017223051708856',
  google_play_id: 'it.prepssm.app',
  appstore_id: 'id1452631957',
  appstore_service_id: 'it.prepssm.service',
  apple_redirect_uri: 'https://www.prepssm.it',
  pushChannel: 'prepssm_push_channel',
  pushName: 'prepSSM.it',
  intercomAppId: 'al08sghm',
  intercomHelpdeskUrl: 'https://intercom.help/prepssm',
  facebook_url: 'https://www.facebook.com/prepssm',
  instagram_url: 'https://www.instagram.com/prepssm/',
  tiktok_url: 'https://www.tiktok.com/@prepssmquizmedicina',
  telegram_url: 'https://t.me/+dnUm8VvwMlA4YWQ0',
  domain: 'www.prepssm.it',
  adwordsKey: 'AW-16489148015/OGweCOfusZ0ZEO_c0bY9',
};
